@import '../variables';
@import '~sweetalert2/src/sweetalert2.scss';

.swal2-container.swal2-backdrop-show,
.swal2-container.swal2-noanimation {
  background: rgba(0, 0, 0, 0.7) !important;
}

.swal2-icon {
  border-color: rgba($mp-primary, 0.7) !important;
  color: $mp-primary !important;
}

.swal2-popup {
  border-radius: 24px !important;
}

.swal2-actions .btn {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
