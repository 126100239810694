@import 'variables';
@import 'components';

$white: $mp-white;
$gray-100: $mp-grey-100;
$gray-200: $mp-grey-200;
$gray-300: $mp-grey-300;
$gray-400: $mp-grey-400;
$gray-500: $mp-grey-500;
$gray-600: $mp-grey-600;
$gray-700: $mp-grey-700;
$gray-800: $mp-grey-800;
$gray-900: $mp-grey-900;
$black: $mp-black;

$primary: $mp-primary;
$secondary: $mp-secondary;
$success: $mp-success;
$info: $mp-info;
$warning: $mp-warning;
$danger: $mp-error;
$light: $mp-white;
$dark: $mp-black;

$border-radius: 12px;

$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1279px
);

$grid-gutter-width: 16px !default;

$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1140px,
        xxl: 1359px
);

$form-check-label-color: $mp-black;

$form-feedback-icon-valid: none;
$form-feedback-icon-invalid: none;

$font-weight-lighter: 100;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 500;
$font-weight-bolder: 700;
$font-weight-base: $font-weight-normal;

$btn-border-radius: 120px;
$btn-border-radius-sm: 120px;
$btn-border-radius-lg: 120px;
$btn-border-width: 1px;
$btn-close-opacity: 1;
$btn-disabled-opacity: 0.45;
$btn-font-weight: $font-weight-bold;

$line-height-base: 1.5;
$line-height-sm: 1.25;
$line-height-lg: 2;

$input-border-color: transparent;
$input-btn-font-size: 0.875rem;
$input-btn-font-size-sm: 0.875rem;
$input-btn-font-size-lg: 1rem;
$input-font-size: 0.875rem;
$input-font-size-sm: 0.875rem;
$input-font-size-lg: 1rem;
$input-font-weight: $font-weight-normal;
$input-font-weight-sm: $font-weight-normal;
$input-font-weight-lg: $font-weight-normal;

$input-border-radius: 12px;
$input-border-radius-sm: 12px;
$input-border-radius-lg: 12px;

$input-btn-padding-y: 9px;
$input-btn-padding-x: 15px;
$input-btn-padding-y-sm: 3px;
$input-btn-padding-x-sm: 12px;
$input-btn-padding-y-lg: 13px;
$input-btn-padding-x-lg: 23px;

$modal-backdrop-opacity: 0.8;
$modal-content-border-radius: 24px;
$modal-content-bg: $mp-background-color;

$enable-negative-margins: true;
$spacers: $mp-spacers;

@import "~bootstrap/scss/mixins/banner";
@include bsBanner("");

// Configuration
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";

// Layout & components
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
//@import "~bootstrap/scss/images";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
//@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
//@import "~bootstrap/scss/accordion";
@import "~bootstrap/scss/breadcrumb";
//@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/progress";
//@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
//@import "~bootstrap/scss/toasts";
@import "~bootstrap/scss/modal";
//@import "~bootstrap/scss/tooltip";
//@import "~bootstrap/scss/popover";
//@import "~bootstrap/scss/carousel";
//@import "~bootstrap/scss/spinners";
//@import "~bootstrap/scss/offcanvas";
//@import "~bootstrap/scss/placeholders";

// Helpers
@import "~bootstrap/scss/helpers";

// Utilities
@import "~bootstrap/scss/utilities/api";

.form-check {
  padding-left: 1.6rem;
}

.form-check-inline {
  margin-right: 2rem;
}

.form-check-label {
  @extend .text-copy;
  color: $mp-black !important;
}

.form-check .form-check-input {
  margin-left: -1.6rem;
}

.form-check-input-lg {
  height: 1.2em;
  width: 1.2em;
}

.form-control::placeholder,
.form-select::placeholder {
  font-weight: $mp-font-weight-light !important;
  color: $mp-grey-600;
}

.input-group-text {
  background-color: $mp-white;
}
.was-validated .form-check-input:valid,
.was-validated .form-check-input:invalid {
  border-color: rgba($mp-black, 0.25);
}

.was-validated .form-check-input:valid:checked {
  background-color: $mp-primary;
  border-color: $mp-primary;
}

.was-validated .form-control:valid,
.was-validated .form-control:invalid {
  border: none;
  outline: none;
}

input:focus,
textarea:focus {
  box-shadow: none !important;
  outline: none !important;
}

nav.navbar {
  .navbar-toggler:focus {
    box-shadow: none;
    outline: none !important;
  }

  .navbar-toggler .icon-bar {
    background: $mp-primary;
    border-radius: 1px;
    display: block;
    height: 2px;
    margin-bottom: 5px;
    pointer-events: none;
    transition: all 250ms;
    width: 20px;
  }

  .navbar-toggler {
    transition: all 250ms;
  }

  .navbar-toggler:not(.collapsed) {
    transform: rotate(90deg);
  }

  .navbar-toggler .icon-bar:nth-of-type(1) {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transform-origin: 0% 0%;
    -ms-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
  }

  .navbar-toggler .icon-bar:nth-of-type(2) {
    opacity: 0;
    filter: alpha(opacity=0);
  }

  .navbar-toggler .icon-bar:nth-of-type(3) {
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-transform-origin: 0% 100%;
    -ms-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
  }

  .navbar-toggler.collapsed .icon-bar:nth-of-type(1) {
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
  }

  .navbar-toggler.collapsed .icon-bar:nth-of-type(2) {
    opacity: 1;
    filter: alpha(opacity=100);
  }

  .navbar-toggler.collapsed .icon-bar:nth-of-type(3) {
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
  }

  .navbar-collapse.collapsing {
    opacity: 0;
    transition: $transition-fade;
  }

  .navbar-collapse.fade:not(.show) {
    opacity: 0;
  }
}

@each $size, $px in $grid-breakpoints {
  @media(min-width: $px) {
    .navbar-expand-#{$size} .navbar-collapse.fade:not(.show) {
      opacity: 1 !important;
    }
  }
}

ol.breadcrumb {
  li.breadcrumb-item {
    @extend .text-body;
    margin-right: 16px;
    padding-bottom: 4px;
    padding-top: 4px;
  }

  li.breadcrumb-item:last-child {
    margin-right: 0;
  }

  li.breadcrumb-item + li.breadcrumb-item::before {
    content: '•' !important;
    font-size: 0.4375rem;
    line-height: 1.625rem;
    margin-top: 2px;
    padding-right: 23px;
  }

  li.breadcrumb-item + li.breadcrumb-item:last-child::before {
    padding-right: 8px;
  }

  li.breadcrumb-item:last-child span {
    background-color: $mp-white;
    border-radius: 8px;
    margin-left: 5px;
    padding: 5px 10px;
  }
}
