@import 'variables';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';

section {
  border-top: 1px solid $mp-grey-400;
  margin-bottom: 60px;
  padding: 60px 0 0;

  &:first-child {
    margin-top: 40px;
  }

  &:last-child {
    margin-bottom: 100px;
  }
}

@include media-breakpoint-up(lg) {
  section:first-child {
    margin-top: 40px;
  }
}
