@import 'variables';

.bg-primary {
  background-color: $mp-primary !important;
  color: $mp-secondary !important;
}

.bg-secondary {
  background-color: $mp-secondary !important;
  color: $mp-primary !important;
}

.bg-tertiary {
  background-color: $mp-tertiary !important;
  color: $mp-primary;
}

.bg-quaternary {
  background-color: $mp-quaternary;
  color: $mp-white;
}

.bg-default {
  background-color: $mp-background-color;
}

a.nav-link.router-link-active {
  color: $mp-quaternary !important;
  font-weight: $mp-font-weight-bold;
}

.card {
  border: 0;
  border-radius: 24px;
}

.badge {
  @extend .text-overline-sm;
  border-radius: 20px;
  font-weight: $mp-font-weight-bolder;
  padding: 4px 10px 4px 12px;
  text-align: center;
}

input {
  color: $mp-primary;
}

input::placeholder {
  @extend .fw-bold;
  color: $mp-primary;
}

.form-check-input {
  &:checked {
    background-color: $mp-primary;
    border-color: $mp-primary;
  }
}

a.enlarge-image {
  cursor: pointer;
}
