@import '../variables';
@import '~vue-select/dist/vue-select.css';

.vs__dropdown-toggle {
  background: $mp-white;
  border-radius: 12px;
  border-color: transparent;
  min-height: 52px;
  padding: 3px 15px 8px;
}

.vs__selected {
  background: $mp-secondary;
  border-color: $mp-tertiary;
  margin-top: 5px;
  margin-right: 5px;
}

.vs--single.vs--open .vs__selected {
  margin-top: 10px;
}

.vs--multiple .vs__selected {
  padding: 5px 10px;
}

.vs__deselect {
  margin-left: 9px;
}

.vs__dropdown-menu {
  border-color: transparent;
}

.vs__search::placeholder {
  font-weight: $mp-font-weight-light !important;
  color: $mp-grey-600;
}
