@import 'variables';
@import '~bootstrap/scss/mixins/buttons';

.btn {
  font-weight: $mp-font-weight-bold;

  line-height: 1.5rem;

  &:focus {
    box-shadow: none !important;
    outline: none !important;
  }

  &:hover {
    background-color: $mp-quaternary !important;
    border-color: $mp-quaternary !important;
    color: $mp-white !important;
  }

  &:disabled,
  &.btn-disabled {
    background-color: $mp-grey-300;
    border-color: $mp-grey-300;
    color: $mp-black;
    cursor: default;
    opacity: 0.60;
    pointer-events: none;
  }

  &.btn-outline-disabled {
    @extend .btn-disabled;
    background-color: transparent;
    color: $mp-grey-600;
  }
}

.btn-important {
  font-size: 1rem !important;
  line-height: 1.5rem !important;
}

.btn-primary {
  color: $mp-white;
}

.btn-secondary {
  color: $mp-black;
}

.btn-tertiary {
  @include button-variant($mp-tertiary, $mp-tertiary, $mp-black);
}

.btn-outline-tertiary {
  @include button-outline-variant($mp-tertiary);
}

.btn-quaternary {
  background-color: $mp-quaternary;
  border-color: $mp-quaternary;
  color: $mp-white;

  &:hover {
    background-color: $mp-primary !important;
    border-color: $mp-primary !important;
  }
}

.btn-white {
  @include button-variant($mp-white, $mp-white, $mp-black);
}

.btn-outline-white {
  @include button-outline-variant($mp-white);
}

.btn-hover-white:hover {
  background-color: $mp-white !important;
  border-color: $mp-white !important;
  color: $mp-primary !important;
}
