//noinspection CssUnknownTarget

@use 'sass:map';

@import '../variables';

$font-name: 'P22 Mackinac';

$font-files: (
        normal: (
                normal: 'P22Mackinac-Book_13',
                italic: 'P22Mackinac-BookItalic_5',
        ),
        bold: (
                normal: 'P22Mackinac-Medium_6',
                italic: 'P22Mackinac-MedItalic_22'
        ),
        bolder: (
                normal: 'P22Mackinac-Bold_23',
                italic: 'P22Mackinac-BoldItalic_7',
        ),
);

@each $weight, $styles in $font-files {
  @each $style, $file in $styles {
    @font-face {
      font-family: $font-name;
      font-weight: map.get($mp-font-weights, $weight);
      font-style: $style;
      font-display: swap;
      src: url('~@/scss/fonts/mackinac/#{$file}.otf') format('otf'),
           url('~@/scss/fonts/mackinac/#{$file}.woff2') format('woff2'),
           local(serif);
    }
  }
}
