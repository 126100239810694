$mp-black: #090909;
$mp-grey: #000;
$mp-grey-900: #000;
$mp-grey-800: #393939;
$mp-grey-700: #4e4e4e;
$mp-grey-600: #959595;
$mp-grey-500: #b3b3b3;
$mp-grey-400: #cecece;
$mp-grey-300: #e3e3e3;
$mp-grey-200: #ececec;
$mp-grey-100: #f8f7f2;

$mp-white: #fff;
$mp-gold: #f1b422;
$mp-silver: #ececec;

$mp-primary: #1e1e1e;
$mp-secondary: #f7f3ee;
$mp-tertiary: #ece3db;
$mp-quaternary: #f97d4e;
$mp-info: #5487f5;
$mp-info-light: #e5edfe;
$mp-success: #00c48c;
$mp-success-light: #d9f6ee;
$mp-alert: #f1b422;
$mp-alert-light: #fff8e7;
$mp-warning: #ffa26b;
$mp-warning-light: #fff1e9;
$mp-error: #ff647c;
$mp-error-light: #ffe8eb;

$mp-hover-dark: #080808;
$mp-hover-light: #ece3db;
$mp-pressed-dark: #080808;
$mp-pressed-light: #ece3db;
$mp-motion-1: #f8f7f2;
$mp-motion-2: #f7f3ee;

$mp-background-color: $mp-motion-1;
$mp-text-color: $mp-primary;

$mp-font-size: 16px;
$mp-font-family-heading: 'P22 Mackinac', sans-serif;
$mp-font-family-base: 'Mallory', sans-serif;

$mp-font-weight-lighter: 200;
$mp-font-weight-light: 300;
$mp-font-weight-normal: 400;
$mp-font-weight-bold: 500;
$mp-font-weight-bolder: 700;

$mp-font-weights: (
        lighter: $mp-font-weight-lighter,
        light: $mp-font-weight-light,
        normal: $mp-font-weight-normal,
        bold: $mp-font-weight-bold,
        bolder: $mp-font-weight-bolder,
);

$mp-spacers: (
        0: 0,
        1: 1px,
        2: 2px,
        3: 4px,
        4: 8px,
        5: 16px,
        6: 20px,
        7: 24px,
        8: 28px,
        9: 32px,
        10: 40px,
        11: 48px,
        12: 56px,
        13: 64px,
        14: 80px,
        15: 96px,
        16: 128px,
);
