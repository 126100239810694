@import 'variables';

@import 'bootstrap';

@import 'plugins/dropzone';
@import 'plugins/flickity';
@import 'plugins/fontawesome';
@import 'plugins/select2';
@import 'plugins/sweetalert2';
@import 'plugins/vue-loading';

@import 'fonts/mackinac';
@import 'fonts/mallory';

@import 'alerts';
@import 'buttons';
@import 'layout';
@import 'type';
@import 'effects';
@import 'components';
@import 'grid';
@import 'form';
@import 'sections';

:root {
  --mp-black: #{$mp-black};
  --mp-grey: #{$mp-grey};
  --mp-grey-900: #{$mp-grey-900};
  --mp-grey-800: #{$mp-grey-800};
  --mp-grey-700: #{$mp-grey-700};
  --mp-grey-600: #{$mp-grey-600};
  --mp-grey-500: #{$mp-grey-500};
  --mp-grey-400: #{$mp-grey-400};
  --mp-grey-300: #{$mp-grey-300};
  --mp-grey-200: #{$mp-grey-200};
  --mp-grey-100: #{$mp-grey-100};
  --mp-white: #{$mp-white};
  --mp-gold: #{$mp-gold};
  --mp-silver: #{$mp-silver};
  --mp-primary: #{$mp-primary};
  --mp-secondary: #{$mp-secondary};
  --mp-tertiary: #{$mp-tertiary};
  --mp-quarternary: #{$mp-quaternary};
  --mp-info: #{$mp-info};
  --mp-info-light: #{$mp-info-light};
  --mp-success: #{$mp-success};
  --mp-success-light: #{$mp-success-light};
  --mp-alert: #{$mp-alert};
  --mp-alert-light: #{$mp-alert-light};
  --mp-warning: #{$mp-warning};
  --mp-warning-light: #{$mp-warning-light};
  --mp-error: #{$mp-error};
  --mp-error-light: #{$mp-error-light};
  --mp-hover-dark: #{$mp-hover-dark};
  --mp-hover-light: #{$mp-hover-light};
  --mp-pressed-dark: #{$mp-pressed-dark};
  --mp-pressed-light: #{$mp-pressed-light};
  --mp-motion-1: #{$mp-motion-1};
  --mp-motion-2: #{$mp-motion-2};
  --mp-bg-color: #{$mp-background-color};
  --mp-font-size: #{$mp-font-size};
  --mp-font-family-heading: #{$mp-font-family-heading};
  --mp-font-family-regular: #{$mp-font-family-base};
}

:focus {
  outline: none;
}

a:not(.navbar-brand, .btn, .btn-link, .nav-link) {
  &:link,
  &:active,
  &:visited {
    color: $mp-quaternary;
    font-weight: $mp-font-weight-bolder;
    transition: ease-in-out 250ms;
  }

  &:hover {
    color: $mp-hover-dark;
  }
}

.form-label.is-required:after {
  color: #878787;
  content: 'Required';
  font-size: 0.875rem;
  font-weight: 100;
  line-height: 1.875rem;
  padding-left: 15px;
}

.star-rating__label {
  color: #ccc !important;
  font-size: 2.5rem !important;
}

.star-rating__label.is-active {
  color: $red !important;
}

.tag {
  display: inline-block;
  font-size: 90%;
  margin-right: 0.5rem;
  padding: 0 0.5rem;
}

.tag:last-child {
  margin-right: 0;
}

.tag.tag-outlined {
  border: 1px solid #c7c7c7;
  color: #575757;
}
