
@import 'scss/main';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';

#app {
  color: $mp-primary;
  font-family: $mp-font-family-base;
  padding: 113px 0 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
}

#nav {
  padding: 30px;

  a {
    color: #2c3e50;
    font-weight: bold;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

@include media-breakpoint-up(md) {
  #app {
    padding-top: 132px;
  }
}

