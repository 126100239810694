.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
  --bs-gutter-x: 20px;
}

@media(min-width: 768px) {
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl,
  .container-xxl {
    --bs-gutter-x: 40px;
  }
}

@media(min-width: 1400px) {
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl,
  .container-xxl {
    max-width: 1359px;
  }
}
