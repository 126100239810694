@import 'variables';

html {
  font-size: $mp-font-size;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

body {
  background-color: $mp-background-color;
  color: $mp-primary;
  font-family: 'Mallory', sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  margin: 0;
  min-width: 380px;
}
