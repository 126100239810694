$dropzone-height: 135px;

@import '../variables';
@import '~dropzone-vue/dist/dropzone-vue.common.css';

.dropzone {
  background: $mp-white;
  min-height: $dropzone-height;
}

.dropzone__box {
  color: #878787;
  background-image: url('data:image/svg+xml,%3csvg width=\'100%25\' height=\'100%25\' xmlns=\'http://www.w3.org/2000/svg\'%3e%3crect width=\'100%25\' height=\'100%25\' fill=\'none\' rx=\'12\' ry=\'12\' stroke=\'%23#{str-slice(#{$mp-grey-600}, -6)}\' stroke-width=\'2.5\' stroke-dasharray=\'5%2c 10\' stroke-dashoffset=\'0\' stroke-linecap=\'round\'/%3e%3c/svg%3e');
  border: 0;
  border-radius: 12px;
}

.dropzone__message {
  cursor: pointer;
  line-height: $dropzone-height;
  text-align: center;
  vertical-align: middle;
  width: 100%;
}

.dropzone__item--style {
  margin: 0;
}

.dropzone__item-thumbnail {
  border-radius: 12px;
}

.dropzone__item-thumbnail img {
  height: -webkit-fill-available !important;
  object-fit: cover;
}

.dropzone__success-mark i {
  color: $mp-success;
}

.dropzone__error-mark {
  color: $mp-error;
}

.dropzone__item-controls {
  z-index: 50;
}
