//noinspection CssUnknownTarget

@use 'sass:map';

@import '../variables';

$font-name: 'Mallory';

$font-files: (
        light: (
                normal: 'Mallory Light',
                italic: 'Mallory Light Italic',
        ),
        normal: (
                normal: 'Mallory Book',
                italic: 'Mallory Book Italic'
        ),
        bold: (
                normal: 'Mallory Medium',
                italic: 'Mallory Medium Italic',
        ),
        bolder: (
                normal: 'Mallory Bold',
                italic: 'Mallory Bold Italic',
        ),
);

@each $weight, $styles in $font-files {
  @each $style, $file in $styles {
    @font-face {
      font-family: $font-name;
      font-weight: map.get($mp-font-weights, $weight);
      font-style: $style;
      font-display: swap;
      src: url('~@/scss/fonts/mallory/#{$file}.ttf') format('ttf'),
           url('~@/scss/fonts/mallory/#{$file}.woff2') format('woff2'),
           local(sans-serif);
    }
  }
}
