.remaining {
  color: #878787;
  font-size: 0.85rem;
  font-weight: 100;
  line-height: 1.875rem;
  text-align: left;
}

.required {
  color: #878787;
  font-size: 0.875rem;
  font-weight: 100;
  line-height: 1.875rem;
  text-align: left;
}

.required::after {
  content: 'Required';
}

.form-label-required::after {
  content: '*';
}
