@import 'variables';

a.nav-link {
  display: block;
  padding: 0 !important;
  position: relative;

  &:link,
  &:active,
  &:visited {
    color: $mp-primary !important;
  }

  &:hover {
    color: $mp-primary !important;
    padding-bottom: 2px !important;
    transition: all .2s ease;

    &::after {
      transform: scaleX(1);
      transform-origin: bottom left;
    }
  }

  &::after {
    background-color: $mp-primary;
    bottom: 0;
    content: '';
    height: 1px;
    left: 0;
    position: absolute;
    transform: scaleX(0);
    transform-origin: bottom right;
    transition: transform 300ms;
    width: 100%;
  }
}
