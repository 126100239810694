@use 'sass:map';

@import 'variables';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.ff-heading {
  font-family: $mp-font-family-heading;
}

.ff-base {
  font-family: $mp-font-family-base;
}

$headings: (
        // Headings with numeric assignment
        1: (font-size: 3.75rem, letter-spacing: -1.5px,    line-height: 1.3333em, alias: 'xlarge'),  // 48px
        2: (font-size: 3rem,    letter-spacing: -1.5px,    line-height: 1.1667em, alias: 'large'),   // 48px
        3: (font-size: 2.75rem, letter-spacing: -1.375px,  line-height: 1.1667em, alias: 'xmedium'), // 44px
        4: (font-size: 2.5rem,  letter-spacing: -1.25px,   line-height: 1.6em,    alias: 'medium'),  // 40px
        5: (font-size: 2rem,    letter-spacing: -0.75px,   line-height: 1.25em,   alias: 'small'),   // 32px
        6: (font-size: 1.5rem,  letter-spacing: -0.75px,   line-height: 1.3333em, alias: 'xsmall'),  // 24px
        7: (font-size: 1.25rem, letter-spacing: -0.50px,   line-height: 1.6em,    alias: 'xxsmall'), // 20px
        8: (font-size: 1rem,    letter-spacing: 0,         line-height: 1.5em,    alias: 'base'),    // 20px
        9: (font-size: 0.75rem, letter-spacing: 0,         line-height: 1.5em,    alias: 'tiny'),    // 12px

        // Headings without numeric assignment (i.e. special cases)
        'small-mobile': (font-size: 1.875rem, letter-spacing: -1.25px, line-height: 1.6em) // 30px
);

$heading-alias-overrides: (
        'xlarge':  (letter-spacing: -1.5px,   line-height: 1.3333em),
        'large':   (letter-spacing: -1.5px,   line-height: 1.1667em),
        'xmedium': (letter-spacing: -1.375px, line-height: 1.1667em),
        'medium':  (letter-spacing: -1.25px,  line-height: 1.6em),
        'small':   (letter-spacing: -0.75px,  line-height: 1.25em),
        'xsmall':  (letter-spacing: -0.75px,  line-height: 1.3333em),
        'xxsmall': (letter-spacing: -0.50px,  line-height: 1.6em),
        'tiny':    (letter-spacing: 0,        line-height: 1.5em),
);

@mixin heading-variant($grid-breakpoint, $heading-size, $heading-attributes) {
  $alias: map.get($heading-attributes, alias);
  $is-responsive: if($grid-breakpoint != null and $grid-breakpoint != xs, true, false);
  $is-native: if(type-of($heading-size) == 'number' and $is-responsive == false and $heading-size <= 6, true, false);
  $breakpoint-class: if($is-responsive, -#{$grid-breakpoint}, '');

  @include media-breakpoint-up($grid-breakpoint) {
    $heading-font-size: map.get($heading-attributes, font-size) or 1rem;
    $heading-letter-spacing: map.get($heading-attributes, letter-spacing) or normal;
    $heading-line-height: map.get($heading-attributes, line-height) or 1;

    .heading#{$breakpoint-class}-#{$heading-size} {
      color: $mp-primary;
      font-family: $mp-font-family-heading;
      font-size: $heading-font-size;
      font-weight: $mp-font-weight-bold;
      letter-spacing: $heading-letter-spacing;
      line-height: $heading-line-height;
      text-transform: none;
    }

    .display#{$breakpoint-class}-#{$heading-size} {
      @extend .heading#{$breakpoint-class}-#{$heading-size};
      font-weight: $mp-font-weight-bolder;
    }

    @if ($is-native) {
      h#{$heading-size} {
        @extend .heading#{$breakpoint-class}-#{$heading-size};
      }
    }

    @if ($alias) {
      .heading#{$breakpoint-class}-#{$alias} {
        @extend .heading#{$breakpoint-class}-#{$heading-size};
      }
    }
  }

  @if ($alias and map.has-key($heading-alias-overrides, $alias)) {
    .heading#{$breakpoint-class}-#{$alias} {
      @each $attribute, $value in map.get($heading-alias-overrides, $alias) {
        #{$attribute}: #{$value};
      }
    }
  }
}

@each $grid-breakpoint, $_ in $grid-breakpoints {
  @each $heading-size, $heading-attributes in $headings {
    @include heading-variant($grid-breakpoint, $heading-size, $heading-attributes);
  }
}

.text-tiny,
.text-caption {
  font-size: 0.75rem;
  font-weight: $mp-font-weight-light;
  letter-spacing: normal;
  line-height: 1.333em;
}

small,
.text-small {
  font-size: 0.875rem;
  font-weight: $mp-font-weight-normal;
  letter-spacing: normal;
  line-height: 1.5;
}

.text-medium,
.text-copy {
  color: $mp-grey-700;
  font-size: 1.125rem;
  font-weight: $mp-font-weight-normal;
  letter-spacing: -0.25px;
  line-height: 1.333em;
}

.text-large,
.text-article {
  font-size: 1.25rem;
  font-weight: $mp-font-weight-normal;
  letter-spacing: -0.25px;
  line-height: 1.6em;
}

label,
.text-label {
  font-size: 0.875rem;
  font-weight: $mp-font-weight-normal;
  line-height: 1.5rem;
}

.text-overline {
  font-size: 0.6875rem;
  font-weight: $mp-font-weight-normal;
  letter-spacing: 2px;
  line-height: 1.333em;
  text-transform: uppercase;
}

.text-overline-sm {
  @extend .text-overline;
  font-size: 0.5625rem;
  line-height: 1.71em;
}

.text-base,
.text-regular,
.text-body,
.text-paragraph {
  font-family: $mp-font-family-base;
  font-size: 1rem;
  font-weight: $mp-font-weight-normal;
  letter-spacing: normal;
  line-height: 1.5em;
}
